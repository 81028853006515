/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, Image, YouTube } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Film-like"} description={"Film-like"}>
        <Column className="pb--30 pt--30" name={"sqszy8c1gs6"} layout={"l13"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Trumpet Concerto<br>"}>
              </Title>

              <Text className="text-box" content={"Karel Mužátko - trumpet<br>Alena Hönigová - harpsichord<br>Concerto for Trumpet, Harpsichord, Prepared piano and Orchestra&nbsp;<br>"}>
              </Text>

              <Button className="btn-box" href={"https://www.youtube.com/watch?v=PGdAiwsTe5g"} content={"YouTube<br>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Button className="btn-box" url={"https://otto-o.com/"} href={"https://otto-o.com/"} target={null} content={"Home"}>
              </Button>

              <Image src={"https://cdn.swbpg.com/t/2002/92dfbf2c829541ee98b541f4cbaffc46_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":432}} srcSet={"https://cdn.swbpg.com/t/2002/92dfbf2c829541ee98b541f4cbaffc46_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2002/92dfbf2c829541ee98b541f4cbaffc46_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2002/92dfbf2c829541ee98b541f4cbaffc46_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2002/92dfbf2c829541ee98b541f4cbaffc46_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"2lu3bx2e70y"} style={{"backgroundColor":"rgba(192, 192, 192, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Epic Music"}>
              </Title>

              <Text className="text-box" content={"Epická hudba všech žánrů<br>Epic music of multiple genres<br>"}>
              </Text>

              <Button className="btn-box" use={"page"} href={"/epicmusic"} content={"Epic Music"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/2002/9a2dded461684b9680b67251da580c85_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":441}} srcSet={"https://cdn.swbpg.com/t/2002/9a2dded461684b9680b67251da580c85_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2002/9a2dded461684b9680b67251da580c85_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2002/9a2dded461684b9680b67251da580c85_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2002/9a2dded461684b9680b67251da580c85_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2002/9a2dded461684b9680b67251da580c85_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"sil3ts81ctm"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Coffee Therapy"}>
              </Title>

              <Text className="text-box" content={"Dvouvětá skladba zkomponovaná pro mého profesora klavíru, Davida Šugárka a jeho stejně skvělého kolegy, cellisty Vojtěcha Urbana\n<br>1. Espresso Doppio\n<br>2. Cappuccino<br>&nbsp;One piece in two movements written for my proffesor fo the piano, David Šugárek and his coleague Vojtěch Urban, excelent cellist.\n<br>1st Espresso Doppio\n<br>2nd Cappuccino"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"VEMOo4g_XZ0"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"aebqmrplqfc"} style={{"backgroundColor":"rgba(192, 192, 192, 1)"}} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/2002/09543d857a0340079be488a1719272e6_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":545}} srcSet={"https://cdn.swbpg.com/t/2002/09543d857a0340079be488a1719272e6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2002/09543d857a0340079be488a1719272e6_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2002/09543d857a0340079be488a1719272e6_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2002/09543d857a0340079be488a1719272e6_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box text-box--center pl--0" style={{"maxWidth":562}} content={"Ocenění za smyčcový kvartet Soothed Soul<br>Award for the string quartet Soothed Soul<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}